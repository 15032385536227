import React from 'react'

import { TimelineStyles } from './styles'

export type TimelineProps = {
  children?: React.ReactNode
  color?: 'gray' | 'white'
}

Timeline.defaultProps = {
  children: null,
  color: 'gray'
}

export function Timeline({ children, color }: TimelineProps) {
  return <TimelineStyles color={color}>{children}</TimelineStyles>
}
