import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTranslation } from 'react-i18next'

const getAge = (dob: string | number | Date) => {
  const birthDate = new Date(dob)
  const difference = Date.now() - birthDate.getTime()
  const age = new Date(difference)

  return Math.abs(age.getUTCFullYear() - 1970)
}

export const useMock = () => {
  const { t } = useTranslation()

  const developer = [
    {
      name: 'Tiago Silva',
      age: getAge('1992-06-21'),
      carrer: t('developer.carrer'),
      phone: '+55 11 95790-2720',
      email: 'tg.solucoesweb@gmail.com'
    }
  ]

  return {
    menu: [
      {
        id: 'about',
        label: t('title.about')
      },
      {
        id: 'skills',
        label: t('title.skills')
      },
      {
        id: 'services',
        label: t('title.services')
      },
      {
        id: 'portfolio',
        label: t('title.portfolio')
      },
      {
        id: 'experience',
        label: t('title.experience')
      },
      {
        id: 'education',
        label: t('title.education')
      },
      {
        id: 'cases',
        label: 'Cases'
      }
    ],
    developer: [
      {
        name: developer[0].name,
        carrer: developer[0].carrer,
        phone: developer[0].phone,
        email: developer[0].email,
        description: t('developer.description', {
          name: developer[0].name,
          age: developer[0].age
        })
      }
    ],
    skills: [
      {
        percentage: 95,
        name: 'Magento 1'
      },
      {
        percentage: 85,
        name: 'Magento 2'
      },
      {
        percentage: 70,
        name: 'Wordpress'
      },
      {
        percentage: 65,
        name: 'Laravel'
      },
      {
        percentage: 70,
        name: 'PHP'
      },
      {
        percentage: 90,
        name: 'JavaScript'
      },
      {
        percentage: 100,
        name: 'HTML'
      },
      {
        percentage: 100,
        name: 'CSS'
      },
      {
        percentage: 100,
        name: 'SASS'
      },
      {
        percentage: 50,
        name: 'SQL'
      },
      {
        percentage: 85,
        name: 'Git'
      },
      {
        percentage: 75,
        name: 'Grunt'
      },
      {
        percentage: 65,
        name: 'Composer'
      },
      {
        percentage: 80,
        name: 'PWA'
      },
      {
        percentage: 75,
        name: 'React'
      },
      {
        percentage: 60,
        name: 'Typescript'
      }
    ],
    services: [
      {
        icon: <FontAwesomeIcon icon="chart-line" />,
        name: 'SEO',
        description: t('services.1.description')
      },
      {
        icon: <FontAwesomeIcon icon="calendar-alt" />,
        name: t('services.2.name'),
        description: t('services.2.description')
      },
      {
        icon: <FontAwesomeIcon icon="shopping-cart" />,
        name: t('services.3.name'),
        description: t('services.3.description')
      },
      {
        icon: <FontAwesomeIcon icon="code" />,
        name: t('services.4.name'),
        description: t('services.4.description')
      }
    ],
    portfolio: [
      {
        image: 'drogaraia.png',
        name: 'Drogaraia',
        description: t('portfolio.1.description'),
        url: 'https://drogaraia.com.br/'
      },
      {
        image: 'drogasil.png',
        name: 'Drogasil',
        description: t('portfolio.2.description'),
        url: 'https://www.drogasil.com.br/'
      },
      {
        image: 'marabraz.png',
        name: 'Marabraz',
        description: t('portfolio.3.description'),
        url: 'https://www.marabraz.com.br/'
      },
      {
        image: 'ideiaseletras.png',
        name: 'Ideias & Letras',
        description: t('portfolio.4.description'),
        url: 'https://www.editoraideiaseletras.com.br/'
      },
      {
        image: 'editorasantuario.png',
        name: 'Editora Santuário',
        description: t('portfolio.5.description'),
        url: 'https://www.editorasantuario.com.br/'
      },
      {
        image: 'sephora.png',
        name: 'Sephora',
        description: t('portfolio.6.description'),
        url: 'https://www.sephora.com.br/'
      },
      {
        image: 'petandfun.png',
        name: 'Pet and Fun',
        description: t('portfolio.7.description'),
        url: 'https://petandfun.com.br/'
      },
      {
        image: 'zoing.png',
        name: 'Zoing',
        description: t('portfolio.8.description'),
        url: 'https://www.zoing.com.br/'
      },
      {
        image: 'pneubarato.png',
        name: 'Pneubarato',
        description: t('portfolio.9.description'),
        url: 'https://www.pneubarato.com.br/'
      },
      {
        image: 'belletonn.png',
        name: 'Belletonn',
        description: t('portfolio.10.description'),
        url: 'https://www.belletonn.com.br/'
      },
      {
        image: 'surty.png',
        name: 'Surty',
        description: t('portfolio.11.description'),
        url: 'https://www.surty.com.br/'
      },
      {
        image: 'tadah.png',
        name: 'tadah',
        description: t('portfolio.12.description'),
        url: 'https://tadah.com.br/'
      }
    ],
    experience: [
      {
        logo: 'sonna.png',
        company: 'Sonna Creative Studio',
        office: t('experience.1.office'),
        period: t('experience.1.period'),
        description: t('experience.1.description')
      },
      {
        logo: 'e-smart.jpg',
        company: 'e-smart',
        office: t('experience.2.office'),
        period: t('experience.2.period'),
        description: t('experience.2.description')
      },
      {
        logo: 'brasilct.png',
        company: 'Brasil/CT',
        office: t('experience.3.office'),
        period: t('experience.3.period'),
        description: t('experience.3.description')
      },
      {
        logo: 'b2w.png',
        company: 'B2W',
        office: t('experience.4.office'),
        period: t('experience.4.period'),
        description: t('experience.4.description')
      },
      {
        logo: 'raiadrogasil.png',
        company: 'RD',
        office: t('experience.5.office'),
        period: t('experience.5.period'),
        description: t('experience.5.description')
      }
    ],
    education: [
      {
        image: 'unibes.png',
        school: 'Unibes',
        course: t('education.1.course'),
        period: '2009 - 2009',
        description: t('education.2.description')
      },
      {
        image: 'senac.png',
        school: 'Senac',
        course: t('education.2.course'),
        period: '2010 - 2011',
        description: t('education.2.description')
      },
      {
        image: 'senac.png',
        school: 'Senac',
        course: t('education.3.course'),
        period: '2011 - 2011',
        description: t('education.3.description')
      },
      {
        image: 'uninove.png',
        school: 'UNINOVE',
        course: t('education.4.course'),
        period: '2011 - 2014',
        description: t('education.4.description')
      },
      {
        image: '4linux.jpg',
        school: '4 Linux',
        course: t('education.5.course'),
        period: '2014 - 2014',
        description: t('education.5.description')
      }
    ],
    freelance: false,
    cases: [
      {
        name: 'Drogasil',
        url: 'https://www.drogasil.com.br/',
        logo: 'drogasil.jpg'
      },
      {
        name: 'Drogaraia',
        url: 'https://www.drogaraia.com.br/',
        logo: 'drogaraia.jpg'
      },
      {
        name: 'Marabraz',
        url: 'https://www.marabraz.com.br/',
        logo: 'marabraz.jpg'
      },
      {
        name: 'Sephora',
        url: 'https://www.sephora.com.br/',
        logo: 'sephora.jpg'
      },
      {
        name: 'Editora Santuário',
        url: 'https://www.editorasantuario.com.br/',
        logo: 'santuario.jpg'
      },
      {
        name: 'Editora Ideias & Letras',
        url: 'https://www.editoraideiaseletras.com.br/',
        logo: 'ideias-e-letras.jpg'
      }
    ]
  }
}
