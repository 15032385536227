import { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { useDeviceDetection } from 'hooks/useDeviceDetection'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { Heading } from 'components/Heading'

import { SkillsStyles, SkillStyle } from './styles'

export function Skills() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  const { isMobile } = useDeviceDetection()

  return (
    <SkillsStyles id="skills">
      <div className="container">
        <Heading level="h2">{t('title.skills')}</Heading>
        <Heading level="h3">{t('title.skills_description')}</Heading>
        <div className="skills">
          <div className={!isMobile ? 'row-cols-4' : 'row-cols-2'}>
            {mock.skills
              .slice(0)
              .reverse()
              .map(item => (
                <div className="col" key={Math.random()}>
                  <SkillStyle percentage={item.percentage}>
                    <div className="progress">
                      <span />
                      <strong>{item.percentage}%</strong>
                    </div>
                    <Heading level="h5">{item.name}</Heading>
                  </SkillStyle>
                </div>
              ))}
          </div>
        </div>
      </div>
    </SkillsStyles>
  )
}
