import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import brazilFlag from 'assets/brazil.svg'
import euaFlag from 'assets/eua.svg'

import { Flag } from './Flag'

import { TranslationStyles } from './styles'

export function I18n() {
  const { i18n } = useTranslation()

  const handleChangeLanguage = useCallback(
    (language: string | undefined) => {
      i18n.changeLanguage(language)
    },
    [i18n]
  )

  return (
    <TranslationStyles>
      <Flag
        image={brazilFlag}
        selected={i18n.language === 'pt-BR'}
        onClick={() => handleChangeLanguage('pt-BR')}
      />
      <Flag
        image={euaFlag}
        selected={i18n.language === 'en-US'}
        onClick={() => handleChangeLanguage('en-US')}
      />
    </TranslationStyles>
  )
}
