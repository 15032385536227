import React from 'react'

import { ButtonStyles } from './styles'

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  children?: React.ReactNode
  color?: 'primary' | 'secondary'
}

Button.defaultProps = {
  onClick: () => {
    undefined
  },
  children: null,
  color: 'primary'
}

export function Button({ onClick, children, color }: ButtonProps) {
  return (
    <ButtonStyles onClick={onClick} color={color}>
      {children}
    </ButtonStyles>
  )
}
