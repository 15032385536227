import ResetStyle from './reset'
import GlobalStyle from './global'

export function Styles() {
  return (
    <>
      <ResetStyle />
      <GlobalStyle />
    </>
  )
}
