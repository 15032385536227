import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled from 'styled-components'

export const AboutStyles = styled.section`
  background-color: #fff;
  text-align: center;

  [class*='row'] {
    align-items: center;
  }

  .photo {
    text-align: right;

    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      text-align: center;
      margin-bottom: 20px;
    }

    img {
      width: 60%;
      margin-left: auto;
      border-radius: 50%;
      border: 15px solid #fff;
    }
  }

  .info {
    text-align: left;

    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      text-align: center;
    }
  }
`
