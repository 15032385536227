import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTranslation } from 'react-i18next'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { FooterStyles } from './styles'

export function Footer() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  return (
    <FooterStyles data-testid="footer">
      <div className="container">
        <div className="copyright-contact-social">
          <div className="copyright" data-testid="copyright">
            {t('copyright')} &copy; tgdeveloper 2022
          </div>
          <div className="contact" data-testid="contact">
            <a
              href={`tel:${mock.developer[0].phone
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .replace(/ /g, '')}`}
            >
              <FontAwesomeIcon icon="phone" />
              {mock.developer[0].phone}
            </a>
            <a href={`mailto:${mock.developer[0].email}`}>
              <FontAwesomeIcon icon="envelope" />
              {mock.developer[0].email}
            </a>
            <a
              href={`https://api.whatsapp.com/send/?phone=${mock.developer[0].phone
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .replace(/ /g, '')}`}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'whatsapp']} />
              {mock.developer[0].phone}
            </a>
          </div>
          <div className="social" data-testid="social">
            <a
              href="http://br.linkedin.com/in/tgdeveloper"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'linkedin']} />
            </a>
            <a
              href="https://www.facebook.com/tg.developer"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </a>
          </div>
        </div>
      </div>
    </FooterStyles>
  )
}
