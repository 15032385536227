const theme = {
  color: {
    blue: {
      500: '#109af7'
    },
    gray: {
      400: '#f4f4f4',
      500: '#777777',
      600: '#333333'
    },
    red: {
      500: '#ff0000'
    }
  },
  background: {
    black: {
      opacity: 'rgb(0 0 0 / 80%)'
    }
  },
  boxshadow: {
    400: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    500: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  },
  font: {
    family: 'Roboto Slab, sans-serif',
    size: {
      small: '12px',
      default: '14px',
      large: '16px',
      extralarge: '20px',
      big: '24px',
      extrabig: '30px'
    }
  },
  container: {
    maxwidth: '1280px',
    gap: '15px'
  }
}

export default theme
