import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { ThemeProvider } from 'styled-components'

import { I18n } from 'components/I18n/I18n'

import { Tgdeveloper } from './context/Tgdeveloper'

import { Header } from './layouts/Header'
import { Main } from './layouts/Main'
import { Footer } from './layouts/Footer'

import theme from './styles/theme'
import { Styles } from './styles'

library.add(fas, fab)

export default function App() {
  return (
    <Tgdeveloper>
      <ThemeProvider theme={theme}>
        <Styles />
        <Header />
        <Main />
        <Footer />
        <I18n />
      </ThemeProvider>
    </Tgdeveloper>
  )
}
