import { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { useDeviceDetection } from 'hooks/useDeviceDetection'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { Heading } from 'components/Heading'

import { AboutStyles } from './styles'

export function About() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  const { isMobile } = useDeviceDetection()

  return (
    <AboutStyles id="about" data-testid="about">
      <div className="container">
        <Heading level="h2">{t('title.about')}</Heading>
        <Heading level="h3">{t('title.about_description')}</Heading>
        <div className={!isMobile ? 'row-cols-2' : 'row-cols-1'}>
          <div className="col">
            <div className="photo" data-testid="photo">
              <img
                src={`${process.env.PUBLIC_URL}images/me.jpg`}
                alt={mock.developer[0].name}
                loading="lazy"
              />
            </div>
          </div>
          <div className="col">
            <div className="info" data-testid="info">
              <Heading level="h4">{mock.developer[0].name}</Heading>
              <Heading level="h5">{mock.developer[0].carrer}</Heading>
              <p>{mock.developer[0].description}</p>
            </div>
          </div>
        </div>
      </div>
    </AboutStyles>
  )
}
