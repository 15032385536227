import { ReactNode } from 'react'

import { HeadingStyles } from './styles'

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: ReactNode
  className?: string
}

Heading.defaultProps = {
  className: null
}

export function Heading({ level, children, className }: HeadingProps) {
  const Heading = level

  return (
    <HeadingStyles>
      <Heading className={className}>{children}</Heading>
    </HeadingStyles>
  )
}
