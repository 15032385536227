import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

export const PortfolioStyles = styled.section`
  padding: 0 !important;
  color: #fff;
  background-color: #000;

  .swiper-slide {
    background-size: cover;
  }
`
export const ProjectStyles = styled.div`
  ${props => css`
    padding-top: 100px;
    height: 100vh;
    position: relative;

    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      height: 80vh;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.3;
      width: 100%;
    }

    .info {
      position: absolute;
      top: 0;
      right: 0;
      width: 30%;
      height: 100%;
      text-align: left;
      z-index: 1;
      padding: 100px ${props.theme.container.gap};
      text-shadow: 2px 2px 8px #000;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        width: 100%;
      }

      h5,
      p {
        display: block;
        color: #fff;
      }

      p {
        margin: 20px 0;
      }

      .button {
        margin-top: 0;
      }
    }
  `}
`
