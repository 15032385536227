import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

interface SkillProps {
  percentage: number
}

export const SkillsStyles = styled.section`
  ${props => css`
    background-color: ${props.theme.color.gray[400]};
    text-align: center;
  `}
`
export const SkillStyle = styled.div<SkillProps>`
  ${props => css`
    margin-top: 40px;
    text-align: center;

    .progress {
      position: relative;
      overflow: hidden;
      display: block;
      margin: 0 auto;
      height: 120px;
      width: 120px;
      line-height: 120px;
      background: ${props.theme.color.gray[500]};
      margin-bottom: 20px;
      border-radius: 50%;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        height: 80px;
        width: 80px;
        line-height: 80px;
      }

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: ${props.theme.color.blue[500]};
      }

      strong {
        position: relative;
        z-index: 2;
        font-size: ${props.theme.font.size.extrabig};
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;

        @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
          font-size: ${props.theme.font.size.big};
        }
      }
    }

    h5 {
      text-transform: initial;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        font-size: ${props.theme.font.size.large};
      }
    }
  `}

  .progress {
    span {
      height: ${props => props.percentage}%;
    }
  }
`
