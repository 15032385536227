import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

export const HeaderStyle = styled.header`
  ${props => css`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    padding: ${props.theme.container.gap} 0;
    background-color: ${props.theme.background.black.opacity};

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    svg {
      font-size: ${props.theme.font.size.big};
    }
  `}
`

export const Logo = styled.a`
  ${props => css`
    cursor: pointer;

    a {
      color: #fff;
    }

    span {
      font-size: ${props.theme.font.size.extrabig};
      display: inline-block;
      padding: 1px 6px 6px 6px;
      margin-right: 5px;
      font-weight: bold;
      background-color: ${props.theme.color.blue[500]};
      border: 4px solid ${props.theme.color.blue[500]};
      color: #fff;
      transition: all 0.2s linear;
    }

    &:hover {
      span {
        background-color: transparent;
        color: ${props.theme.color.blue[500]};
      }
    }
  `}
`

export const Nav = styled.nav`
  ${props => css`
    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      position: fixed;
      background-color: ${props.theme.background.black.opacity};
      top: calc(${props.theme.container.gap} * 5);
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      transition: all 0.2s linear;
      transform: translate(-100%);

      &.open {
        transform: translate(0);
      }
    }

    ul {
      display: flex;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        display: block;
      }

      li {
        position: relative;
        text-transform: uppercase;

        a {
          color: #fff;
          display: block;
          padding: 10px 15px;
          cursor: pointer;

          @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
            padding: 15px;
          }

          &:after {
            content: '';
            position: absolute;
            height: 4px;
            left: 0;
            bottom: 0;
            width: 0%;
            transition: all 0.2s linear;
            background: ${props.theme.color.blue[500]};
          }

          &:hover,
          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  `}
`
