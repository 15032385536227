import { useContext } from 'react'

import Scroll from 'react-scroll'

import { useTranslation } from 'react-i18next'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { Heading } from 'components/Heading'
import { Button } from 'components/Button'

import { FreelaStyles } from './styles'

export function Freela() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  const scroll = Scroll.scroller

  return (
    <FreelaStyles>
      <div className="container">
        <div className="freela">
          <Heading level="h2">
            {mock.freelance
              ? t('freelance.available')
              : t('freelance.unavailable')}
          </Heading>
          <Button
            color="secondary"
            onClick={() => {
              scroll.scrollTo('cases', {
                spy: true,
                smooth: true
              })
            }}
          >
            {t('buttons.contact')}
          </Button>
        </div>
      </div>
    </FreelaStyles>
  )
}
