import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

export const IntroStyles = styled.section`
  ${props => css`
    display: flex;
    align-items: center;
    background-color: ${props.theme.background.black.opacity};
    color: #fff;
    height: 100vh;

    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      height: 80vh;
    }

    h2,
    h3 {
      line-height: 80px;
      display: block;
      color: #fff;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        line-height: 40px;
      }
    }

    h2 {
      font-size: ${props.theme.font.size.extrabig};
      padding: 0;
      border: 0;
      font-weight: normal;
      text-transform: none;
    }

    h3 {
      font-size: calc(${props.theme.font.size.extrabig} * 2);
      margin-bottom: 30px;
      font-weight: bold;
      text-transform: uppercase;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        font-size: ${props.theme.font.size.extrabig};
      }
    }
  `}
`
