import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle`
   ${props => css`
     body {
       font-family: ${props.theme.font.family};
       font-size: ${props.theme.font.size.default};
       color: ${props.theme.color.gray[500]};
       background-image: url(../images/bg.jpg);
       background-repeat: no-repeat;
       background-size: cover;
       background-attachment: fixed;
       overflow-x: hidden;
     }

     .container {
       width: 100%;
       margin-left: auto;
       margin-right: auto;
       padding-left: ${props.theme.container.gap};
       padding-right: ${props.theme.container.gap};
       max-width: ${props.theme.container.maxwidth};
     }

     a {
       text-decoration: none;
       color: ${props.theme.color.gray[500]};
       transition: all 0.2s linear;

       &:hover {
         color: ${props.theme.color.blue[500]};
       }
     }

     small {
       font-size: ${props.theme.font.size.small};
       line-height: calc(${props.theme.font.size.small} + 10px);
     }

     p {
       line-height: 24px;
     }

     svg {
       transition: all 0.2s linear;
     }

     .row-cols-1,
     .row-cols-2,
     .row-cols-4 {
       display: flex;
       flex-wrap: wrap;
       margin-left: calc(-${props.theme.container.gap} / 2);
       margin-right: calc(-${props.theme.container.gap} / 2);

       & > .col {
         flex: 0 0 auto;
         padding-left: calc(${props.theme.container.gap} / 2);
         padding-right: calc(${props.theme.container.gap} / 2);
       }
     }

     .row-cols-1 {
       & > .col {
         width: 100%;
       }
     }

     .row-cols-2 {
       & > .col {
         width: 50%;
       }
     }

     .row-cols-4 {
       & > .col {
         width: 25%;
       }
     }

     .swiper {
       .swiper-button-prev,
       .swiper-button-next {
         color: ${props.theme.color.blue[500]};
         cursor: pointer;
       }

       .swiper-button-prev {
         left: calc(${props.theme.container.gap} * 2);
       }

       .swiper-button-next {
         right: calc(${props.theme.container.gap} * 2);
       }

       .swiper-pagination {
         bottom: calc(${props.theme.container.gap} * 2);

         @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
           bottom: ${props.theme.container.gap};
         }

         .swiper-pagination-bullet {
           width: 14px;
           height: 14px;
           margin: 0 8px;
           background: ${props.theme.color.gray[500]};

           @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
             width: 10px;
             height: 10px;
             margin: 0 4px;
           }

           &.swiper-pagination-bullet-active {
             background: ${props.theme.color.blue[500]};
           }
         }
       }
     }
   `}
`
