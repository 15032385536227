import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

import { TimelineProps } from '.'

const COLOR = {
  gray: css`
    .timeline {
      .info {
        background: ${props => props.theme.color.gray[400]};
      }
      &:after {
        background: ${props => props.theme.color.gray[400]};
      }
    }
  `,
  white: css`
    .timeline {
      .info {
        background: white;
      }

      &:after {
        background: white;
      }
    }
  `
}

export const TimelineStyles = styled.div<TimelineProps>`
  ${props => css`
    width: 60%;
    margin: 0 auto;

    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      width: 100%;
    }

    .timeline {
      padding-bottom: calc(${props.theme.container.gap} * 5);
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        height: 100%;
        width: 4px;
      }

      .logo {
        img {
          border: 6px solid #fff;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          box-shadow: ${props.theme.boxshadow[500]};
        }
      }

      .info {
        position: relative;
        z-index: 2;
        padding: 20px;
        width: 100%;

        small {
          display: block;
        }

        p {
          white-space: pre-line;
        }
      }

      &:nth-child(even) {
        direction: rtl;

        .info {
          direction: ltr;
          text-align: right;
          margin-right: calc(${props.theme.container.gap} * 2);
        }
      }

      &:nth-child(odd) {
        .info {
          text-align: left;
          margin-left: calc(${props.theme.container.gap} * 2);
        }
      }

      &:last-child {
        padding-bottom: 0;
        &:after {
          display: none;
        }
      }
    }
  `}

  ${props => props.color && COLOR[props.color]}
`
