import { useContext } from 'react'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useTranslation } from 'react-i18next'

import { useDeviceDetection } from 'hooks/useDeviceDetection'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { Heading } from 'components/Heading'
import { Button } from 'components/Button'

import { PortfolioStyles, ProjectStyles } from './styles'

export function Portfolio() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  const { isMobile } = useDeviceDetection()

  return (
    <PortfolioStyles id="portfolio">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={15}
        slidesPerView={1}
        navigation={!isMobile}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        {mock.portfolio
          .slice(0)
          .reverse()
          .map(item => (
            <SwiperSlide key={Math.random()}>
              <ProjectStyles>
                <img
                  src={`${process.env.PUBLIC_URL}images/portfolio/${item.image}`}
                  alt={item.name}
                />
                <div className="info">
                  <Heading level="h5">{item.name}</Heading>
                  <p>{item.description}</p>
                  <Button
                    color="secondary"
                    onClick={() => {
                      window.open(item.url, '_blank')
                    }}
                  >
                    {t('buttons.access')}
                  </Button>
                </div>
              </ProjectStyles>
            </SwiperSlide>
          ))}
      </Swiper>
    </PortfolioStyles>
  )
}
