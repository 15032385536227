import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

export const FreelaStyles = styled.section`
  ${props => css`
    background-color: ${props.theme.background.black.opacity};
    padding: 0 !important;

    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      padding: calc(${props.theme.container.gap} * 2) 0 !important;
    }

    .freela {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(${props.theme.container.gap} * 3) 0 !important;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        padding: 0 !important;
      }

      h2 {
        border: 0;
        padding: 0;
        margin: 0;
        color: #fff;
        text-transform: uppercase;
        text-align: right;

        @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
          font-size: ${props.theme.font.size.large};
          line-height: 24px;
        }
      }

      button {
        margin: 0 0 0 calc(${props.theme.container.gap} * 2);
      }
    }
  `}
`
