export default {
  translations: {
    title: {
      about: 'About',
      about_description: 'A summary about me',
      skills: 'Skills',
      skills_description: 'I already programmed in',
      services: 'Services',
      services_description: 'What sets me apart',
      portfolio: 'Portfolio',
      experience: 'Experience',
      experience_description: "Companies I've worked for",
      education: 'Education',
      education_description: 'Courses and Training',
      cases_description: 'I am proud to have participated in these projects'
    },
    buttons: {
      more_information: 'More information',
      access: 'Access',
      contact: 'Contact'
    },
    developer: {
      carrer: 'Front-End Developer',
      description:
        "My name is {{name}}, I'm {{age}} years old, I'm Brazilian, I know several programming languages, I work well in a team, I'm proactive and self-taught. I've participated in several projects, from creating simple websites to virtual stores, games and mobile/responsive websites."
    },
    intro: {
      welcome_message: 'Welcome to my site!',
      my_name: 'My name is {{name}}',
      am_i: "I'm a {{carrer}}",
      specialty: 'Magento Specialist.'
    },
    services: {
      1: {
        description:
          'I develop with SEO in mind, structuring the code for the best results on Google and other search engines.'
      },
      2: {
        name: 'Deadline',
        description:
          'Agility in development, code organized and easy to understand, respecting and meeting deadlines.'
      },
      3: {
        name: 'Magento Specialist',
        description:
          'Since 2013 working with the largest ecommerce platform in the world, adding great cases in my career.'
      },
      4: {
        name: 'Web Developer',
        description:
          'For over 8 years in the market, I have been a front-end and back-end programmer, dedicating myself to the front end.'
      }
    },
    portfolio: {
      1: {
        description:
          'When I was part of the E-smart team, I participated in this project, building and architecting the entire front-end, we launched the Drogaraia store in Magento 1, with a desktop and mobile theme.'
      },
      2: {
        description:
          'When I was part of the E-smart team, I participated in this project, building and architecting the entire front-end, we launched the Drogasil store in Magento 1, with a desktop and mobile theme.'
      },
      3: {
        description:
          'When I was part of the E-smart team, I participated in this project, building and architecting the entire front-end, we launched the Marabraz store in Magento 1, with a desktop and mobile theme.'
      },
      4: {
        description:
          'When I was part of the B2W team, I participated in this project, building and architecting the entire front-end, we launched the Ideias & Letras store in Magento 1, with a desktop and mobile theme.'
      },
      5: {
        description:
          'When I was part of the B2W team, I participated in this project, building and architecting the entire front-end, we launched the Editora Santuário store in Magento 1, with a desktop and mobile theme.'
      },
      6: {
        description:
          'When I was part of the B2W team, I participated in this project, building and architecting the entire front-end, we launched the Sephora store in Magento 1, with a desktop and mobile theme.'
      },
      7: {
        description:
          'I developed this store theme in Magento 1 as a freelancer, mobile and desktop theme.'
      },
      8: {
        description:
          'I developed this store theme in Magento 2 as a freelancer, mobile and desktop theme.'
      },
      9: {
        description:
          'I developed this store theme in Magento 2 as a freelancer, mobile and desktop theme.'
      },
      10: {
        description:
          'I developed this store theme in Magento 2 as a freelancer, mobile and desktop theme.'
      },
      11: {
        description:
          'I developed this store theme in Magento 2 as a freelancer, mobile and desktop theme.'
      },
      12: {
        description:
          'I developed this store theme in Magento 2 as a freelancer, mobile and desktop theme.'
      }
    },
    experience: {
      1: {
        office: 'Front End Developer',
        period: 'November 2012 - March 2013 · 5 months',
        description:
          'I was part of a project for the Government of São Paulo, where Interactive Games were being produced for schools, I created several Layouts and programmed some games in jQuery/JavaScript.'
      },
      2: {
        office: 'Front End Developer',
        period: 'September 2013 - May 2015 · 1 year 9 months',
        description:
          'I worked with the Wordpress platform, I was responsible for maintaining existing projects or creating new projects.'
      },
      3: {
        office: 'Magento Front End Developer',
        period: 'April 2013 - June 2013 · 3 months',
        description:
          'I was part of the project team, working with module development, creation and maintenance of stores in Magento.'
      },

      4: {
        office: 'Senior Front End Developer',
        period: 'May 2015 - August 2020 5 years 4 months',
        description:
          'I worked in the project team, with development and maintenance of stores in Magento, mobile/responsive themes and module development.'
      },
      5: {
        office: 'Senior Front End Developer',
        period: 'August 2020 - so far',
        description:
          'I worked at the Marketplace Squad where we launched Marktplace for Magento 2, I moved part of the Onboarding Squad where we built an interface in React for Seller registration and I continue at Squad providing maintenance and improvements.'
      }
    },
    education: {
      1: {
        course: 'Web Designer | Office Routines',
        description: `[6 Months - Web] Web Designer Kurdish students learn to design, create, develop, administer and publish web sites and banners for internet and intranet. Using HTML, CSS AND JAVASCRIPT languages. With the tools: Dreamweaver, Fireworks, Flash and Photoshop.
        [6 Months - Routines] These workshops aim to make young people aware of attitudes, attitudes and demands of professional life in its social aspect. They also offer basic knowledge necessary for the contemporary worker. The workshops are: Personal presentation, Interpersonal relationships at work, Customer service, Entrepreneurship, Administrative procedures, Basic computing.`
      },
      2: {
        course: 'Learning in Administrative Services',
        description:
          'The Senac Learning Program has a title focused on Management and Business, which aims to encourage the development of skills for the personal and professional improvement of apprentices who work in different areas and departments.'
      },
      3: {
        course: 'Administration in Accounts Payable, Receivable and Treasury',
        description:
          "Accounts payable, accounts receivable and treasury routines observing the company,'s rules and procedures, using appropriate work tools, in accordance with specific legislation, evaluating situations with a critical and ethical sense to support financial management."
      },
      4: {
        course: 'Technology in Internet Systems',
        description:
          'The Technologist in Internet Systems deals with the development of programs, interfaces and applications, electronic commerce and marketing, as well as websites and portals for the Internet and intranet.'
      },
      5: {
        course: 'PHP developer',
        description: `WEB DEVELOPMENT WITH PHP
        OBJECT-ORIENTED DEVELOPMENT WITH PHP
        ENTERPRISE DEVELOPMENT WITH PHP
        SECURITY IN PHP
        ZEND FRAMEWORK 2.0`
      }
    },
    freelance: {
      available: 'Available for freelance',
      unavailable: 'Unavailable for freelance'
    },
    cases: {
      resume:
        "Over all these years I participated in some projects that changed my career as a programmer, acting as a front-end programmer I developed all the architecture and implemented the themes of these large stores, all of them used the Magento platform, some changed platforms, but I'll leave it registered here these great brands."
    },
    copyright: 'All rights reserved'
  }
}
