import { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { useDeviceDetection } from 'hooks/useDeviceDetection'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { Heading } from 'components/Heading'

import { ServicesStyles } from './styles'

export function Services() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  const { isMobile } = useDeviceDetection()

  return (
    <ServicesStyles id="services">
      <div className="container">
        <Heading level="h2">{t('title.services')}</Heading>
        <Heading level="h3">{t('title.services_description')}</Heading>
        <div className="services">
          <div className={!isMobile ? 'row-cols-4' : 'row-cols-2'}>
            {mock.services
              .slice(0)
              .reverse()
              .map(item => (
                <div className="col" key={Math.random()}>
                  <div className="service">
                    <div className="icon">{item.icon}</div>
                    <strong>{item.name}</strong>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </ServicesStyles>
  )
}
