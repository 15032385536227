import { createContext } from 'react'

import { TgdeveloperContextData, Props } from 'types'

import { useMock } from 'hooks/useMock'

export const TgdeveloperContext = createContext<TgdeveloperContextData>(
  {} as TgdeveloperContextData
)

export function Tgdeveloper({ children }: Props) {
  const mock = useMock()

  return (
    <TgdeveloperContext.Provider value={mock}>
      {children}
    </TgdeveloperContext.Provider>
  )
}
