import { Intro } from '../../pages/Intro'
import { About } from '../../pages/About'
import { Skills } from '../../pages/Skills'
import { Services } from '../../pages/Services'
import { Portfolio } from '../../pages/Portfolio'
import { Experience } from '../../pages/Experience'
import { Education } from '../../pages/Education'
import { Freela } from '../../pages/Freela'
import { Cases } from '../../pages/Cases'

import { MainStyles } from './styles'

export function Main() {
  return (
    <MainStyles data-testid="main">
      <Intro />
      <About />
      <Skills />
      <Services />
      <Portfolio />
      <Experience />
      <Education />
      <Freela />
      <Cases />
    </MainStyles>
  )
}
