import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

import { ButtonProps } from '.'

const COLOR = {
  primary: css`
    background: #fff;
    color: ${props => props.theme.color.blue[500]};
    border-color: ${props => props.theme.color.blue[500]};

    &:hover {
      color: white;
      background: ${props => props.theme.color.blue[500]};
    }
  `,
  secondary: css`
    background: transparent;
    color: #fff;
    border-color: #fff;

    &:hover {
      color: ${props => props.theme.background.black.opacity};
      background: #fff;
    }
  `
}

export const ButtonStyles = styled.button<ButtonProps>`
  ${props => css`
    display: inline-block;
    margin-top: 20px;
    font-family: ${props.theme.font.family};
    font-weight: bold;
    padding: 16px ${props.theme.container.gap};
    font-size: ${props.theme.font.size.large};
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 2px solid;
    transition: all 0.2s linear;
    cursor: pointer;

    @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
      font-size: ${props.theme.font.size.default};
    }
  `}

  ${props => props.color && COLOR[props.color]}
`
