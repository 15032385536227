import { useContext } from 'react'

import { Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useTranslation } from 'react-i18next'

import { useDeviceDetection } from 'hooks/useDeviceDetection'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { Heading } from 'components/Heading'

import { CasesStyles, CaseStyles } from './styles'

export function Cases() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  const { isMobile } = useDeviceDetection()

  return (
    <CasesStyles id="cases">
      <div className="container">
        <Heading level="h2">Cases</Heading>
        <Heading level="h3">{t('title.cases_description')}</Heading>
        <p>{t('cases.resume')}</p>
        <Swiper
          modules={[Pagination, Scrollbar, A11y]}
          spaceBetween={15}
          slidesPerView={!isMobile ? 4 : 2}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {mock.cases
            .slice(0)
            .reverse()
            .map(item => (
              <SwiperSlide key={Math.random()}>
                <CaseStyles>
                  <a
                    href={item.url}
                    title={item.name}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}images/cases/${item.logo}`}
                      alt={item.name}
                      loading="lazy"
                    />
                  </a>
                </CaseStyles>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </CasesStyles>
  )
}
