import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

export const MainStyles = styled.main`
  ${props => css`
    margin-top: calc(${props.theme.container.gap} * 5);

    section {
      padding: calc(${props.theme.container.gap} * 5) 0;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        padding: calc(${props.theme.container.gap} * 3) 0;
      }
    }
  `}
`
