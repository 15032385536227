import styled, { css } from 'styled-components'

export const HeadingStyles = styled.div`
  ${props => css`
    h1 {
      font-size: ${props.theme.font.size.big};
    }

    h2,
    h3 {
      margin-bottom: calc(${props.theme.container.gap} * 2);
    }

    h2 {
      display: inline-block;
      font-weight: bold;
      letter-spacing: 2px;
      font-size: ${props.theme.font.size.extralarge};
      padding: 16px ${props.theme.container.gap};
      text-transform: uppercase;
      border-top: 2px solid ${props.theme.color.blue[500]};
      border-bottom: 2px solid ${props.theme.color.blue[500]};
      line-height: calc(${props.theme.font.size.extralarge} + 10px);
    }

    h3 {
      font-size: ${props.theme.font.size.extralarge};
      line-height: calc(${props.theme.font.size.extralarge} + 10px);
    }

    h4,
    h5 {
      font-weight: bold;
      text-transform: uppercase;
    }

    h4 {
      font-size: ${props.theme.font.size.large};
      line-height: calc(${props.theme.font.size.large} + 10px);
      color: ${props.theme.color.blue[500]};
    }

    h5 {
      font-size: ${props.theme.font.size.extralarge};
      line-height: calc(${props.theme.font.size.large} + 10px);
      color: #000;
    }
  `}
`
