export default {
  translations: {
    title: {
      about: 'Sobre',
      about_description: 'Um resumo sobre mim',
      skills: 'Habilidades',
      skills_description: 'Eu já programei em',
      services: 'Serviços',
      services_description: 'O que me diferencia',
      portfolio: 'Portfólio',
      experience: 'Experiência',
      experience_description: 'Empresas em que eu já trabalhei',
      education: 'Educação',
      education_description: 'Cursos e Treinamentos',
      cases_description: 'Tenho orgulho de ter participado desses projetos'
    },
    buttons: {
      more_information: 'Mais informações',
      access: 'Acessar',
      contact: 'Contato'
    },
    developer: {
      carrer: 'Programador Front-End',
      description:
        'Meu nome é {{name}}, tenho {{age}} anos, sou brasileiro, conheço várias linguagens de programação, trabalho bem em equipe, sou pró-ativo e autodidata. Já participei de diversos projetos, desde a criação de sites simples até lojas virtuais, jogos e sites mobile/responsivos.'
    },
    intro: {
      welcome_message: 'Bem vindo ao meu site!',
      my_name: 'Meu nome é {{name}}',
      am_i: 'Eu sou {{carrer}}',
      specialty: 'Especialista em Magento.'
    },
    services: {
      1: {
        description:
          'Desenvolvo pensando em SEO, estruturando o código para o melhor resultado no Google e outros buscadores.'
      },
      2: {
        name: 'Prazo',
        description:
          'Agilidade no desenvolvimento, código organizado e de fácil entendimento, respeitando e cumprindo prazos.'
      },
      3: {
        name: 'Especialista em Magento',
        description:
          'Desde 2013 trabalhando com a maior plataforma de ecommerce do mundo, agregando grandes cases em minha carreira.'
      },
      4: {
        name: 'Desenvolvedor Web',
        description:
          'Há mais de 8 anos no mercado, sou programador front-end e back-end, me dedicando ao front-end.'
      }
    },
    portfolio: {
      1: {
        description:
          'Quando eu fazia parte do time da E-smart, participei desse projeto, construindo e arquitetando todo o front-end, lançamos a loja Drogaraia em Magento 1, com tema desktop e mobile.'
      },
      2: {
        description:
          'Quando eu fazia parte do time da E-smart, participei desse projeto, construindo e arquitetando todo o front-end, lançamos a loja Drogasil em Magento 1, com tema desktop e mobile.'
      },
      3: {
        description:
          'Quando eu fazia parte do time da E-smart, participei desse projeto, construindo e arquitetando todo o front-end, lançamos a loja Marabraz em Magento 1, com tema desktop e mobile.'
      },
      4: {
        description:
          'Quando eu fazia parte do time da B2W, participei desse projeto, construindo e arquitetando todo o front-end, lançamos a loja Ideias & Letras em Magento 1, com tema desktop e mobile.'
      },
      5: {
        description:
          'Quando eu fazia parte do time da B2W, participei desse projeto, construindo e arquitetando todo o front-end, lançamos a loja Editora Santuário em Magento 1, com tema desktop e mobile.'
      },
      6: {
        description:
          'Quando eu fazia parte do time da B2W, participei desse projeto, construindo e arquitetando todo o front-end, lançamos a loja Sephora em Magento 1, com tema desktop e mobile.'
      },
      7: {
        description:
          'Eu desenvolvi o tema dessa loja em Magento 1 como freelancer, tema mobile e desktop.'
      },
      8: {
        description:
          'Eu desenvolvi o tema dessa loja em Magento 2 como freelancer, tema mobile e desktop.'
      },
      9: {
        description:
          'Eu desenvolvi o tema dessa loja em Magento 2 como freelancer, tema mobile e desktop.'
      },
      10: {
        description:
          'Eu desenvolvi o tema dessa loja em Magento 2 como freelancer, tema mobile e desktop.'
      },
      11: {
        description:
          'Eu desenvolvi o tema dessa loja em Magento 2 como freelancer, tema mobile e desktop.'
      },
      12: {
        description:
          'Eu desenvolvi o tema dessa loja em Magento 2 como freelancer, tema mobile e desktop.'
      }
    },
    experience: {
      1: {
        office: 'Programador Front End',
        period: 'Novembro de 2012 - Março de 2013 · 5 meses',
        description:
          'Fiz parte de um projeto para o Governo de São Paulo, onde estavam sendo produzidos Jogos Interativos para as escolas, criei diversos Layouts e programei alguns jogos em jQuery/JavaScript.'
      },
      2: {
        office: 'Programador Front End',
        period: 'Setembro de 2013 - Maio de 2015 · 1 ano 9 meses',
        description:
          'Trabalhei com a plataforma Wordpress, era responsável pela manutenção de projetos existentes ou criação de novos projetos.'
      },
      3: {
        office: 'Programador Front-End Magento',
        period: 'Abril de 2013 - Junho de 2013 · 3 meses',
        description:
          'Fiz parte da equipe de projetos, trabalho com desenvolvimento de módulos, criação e manutenção de lojas em Magento.'
      },
      4: {
        office: 'Programador Front-End Senior',
        period: 'Maio de 2015 - Agosto de 2020 · 5 anos 4 meses',
        description:
          'Trabalhei na equipe de projetos, com desenvolvimento e manutenção de lojas em Magento, temas mobile/responsivo e desenvolvimento de módulos.'
      },
      5: {
        office: 'Programador Front-End Senior',
        period: 'Agosto de 2020 - até o momento',
        description:
          'Trabalhei na Squad de Marketplace onde lançamos o Marktplace para o Magento 2, mudei parte da Squad de Onboarding onde construímos uma interface em React para o cadastro do Seller e continuo na Squad dando manutenções e fazendo melhorias.'
      }
    },
    education: {
      1: {
        course: 'Web Designer | Rotinas de Escritório',
        description: `[6 Meses - Web] Os alunos do curdo de Web Designer aprendem a projetar, criar, desenvolver, administrar e publicar web sites e banners para internet e intranet. Utilizando as linguagens HTML, CSS E JAVASCRIPT. Com as ferramentas: Dreamweaver, Fireworks, Flash e Photoshop.
        [6 Meses - Rotinas] Essas oficinas têm por objetivo sensibilizar os jovens quanto às posturas, atitudes e exigências da vida profissional em seu aspecto social. Também oferecem conhecimentos básicos necessários ao trabalhador contemporâneo. As oficinas são: Apresentação pessoal, Relações interpessoais no trabalho, Atendimento ao cliente, Empreendedorismo, Procedimentos administrativos, Informática básica.`
      },
      2: {
        course: 'Aprendizagem em serviços Administrativos',
        description:
          'O Programa Aprendizagem do Senac conta com um título focado em Gestão e Negócios, que tem por objetivo favorecer o desenvolvimento de competências para o aprimoramento pessoal e profissional dos aprendizes que atuam nas diversas áreas e departamentos.'
      },
      3: {
        course: 'Adminstração em Contas a Pagar, Receber e Tesouraria',
        description:
          'Rotinas de contas a pagar, contas a receber e tesouraria observando normas e procedimentos da empresa, usando ferramentas de trabalho adequadas, de acordo com legislação especifica, avaliando as situações com senso crítico e ético para subsidiar a gestão financeira.'
      },
      4: {
        course: 'Tecnologia em Sistemas para Internet',
        description:
          'O Tecnólogo em Sistemas para Internet ocupa-se do desenvolvimento de programas, de interfaces e aplicativos, do comércio e do marketing eletrônico, além de sites e portais para internet e intranet.'
      },
      5: {
        course: 'Desenvolvedor PHP',
        description: `DESENVOLVIMENTO WEB COM PHP
        DESENVOLVIMENTO ORIENTADO A OBJETOS COM PHP
        DESENVOLVIMENTO ENTERPRISE COM PHP
        SEGURANÇA EM PHP
        ZEND FRAMEWORK 2.0`
      }
    },
    freelance: {
      available: 'Disponível para freelance',
      unavailable: 'Indisponível para freelance'
    },
    cases: {
      resume:
        'Ao longo de todos esses anos eu participei de alguns projetos que mudaram minha carreira como programador, atuando como programador front-end eu desenvolvi toda arquitetura e implementei os temas dessas grandes lojas, todas usavam a plataforma Magento, algumas mudaram de plataformar, mas vou deixar registrado aqui essas grandes marcas.'
    },
    copyright: 'Todos os direitos reservados'
  }
}
