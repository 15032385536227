import styled, { css } from 'styled-components'

export const CasesStyles = styled.section`
  ${props => css`
    background-color: #fff;
    text-align: center;
    padding-bottom: calc(${props.theme.container.gap} * 3) !important;

    .swiper {
      padding: calc(${props.theme.container.gap} * 3) 0;

      .swiper-pagination {
        bottom: 0;
      }
    }
  `}
`
export const CaseStyles = styled.div`
  text-align: center;

  img {
    margin: 0 auto;
    width: 80%;
  }
`
