import { useState, useEffect } from 'react'

import { useMediaQuery } from 'react-responsive'

export const MOBILE_DEVICE = { minWidth: 0, maxWidth: 767 }

export function useDeviceDetection() {
  const mobileDevice = useMediaQuery(MOBILE_DEVICE)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(mobileDevice)
  }, [mobileDevice])

  return { isMobile }
}
