import { useContext } from 'react'

import Typed from 'react-typed'

import Scroll from 'react-scroll'

import { Heading } from 'components/Heading'
import { Button } from 'components/Button'

import { useTranslation } from 'react-i18next'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { IntroStyles } from './styles'

export function Intro() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  const scroll = Scroll.scroller

  return (
    <IntroStyles id="intro">
      <div className="container">
        <Heading level="h2">{t('intro.welcome_message')}</Heading>
        <Heading level="h3">
          <Typed
            strings={[
              t('intro.my_name', { name: mock.developer[0].name }),
              t('intro.am_i', { carrer: mock.developer[0].carrer }),
              t('intro.specialty')
            ]}
            typeSpeed={100}
          />
        </Heading>
        <Button
          color="secondary"
          onClick={() =>
            scroll.scrollTo('about', {
              spy: true,
              smooth: true
            })
          }
        >
          {t('buttons.more_information')}
        </Button>
      </div>
    </IntroStyles>
  )
}
