import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

export const FooterStyles = styled.footer`
  ${props => css`
    background-color: ${props.theme.background.black.opacity};
    color: #fff;
    padding: ${props.theme.container.gap} 0;

    a {
      color: #fff;
      margin-left: ${props.theme.container.gap};

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        display: block;
        margin-left: 0;
      }

      &:hover {
        color: ${props.theme.color.blue[500]};
      }

      svg {
        vertical-align: top;
        margin-right: 5px;
      }
    }

    .copyright-contact-social {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      & > div {
        @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
          width: 100%;
          text-align: center;
        }
      }

      .contact {
        @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
          margin: calc(${props.theme.container.gap} * 2);
          a {
            margin-bottom: ${props.theme.container.gap};
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .social {
        @media (max-width: ${MOBILE_DEVICE.maxWidth}px) {
          margin-bottom: 0;
          display: flex;
          justify-content: center;

          a {
            margin-left: ${props.theme.container.gap};
          }
        }
        svg {
          font-size: ${props.theme.font.size.extralarge};
          margin-right: 0;
        }
      }
    }
  `}
`
