import { useContext, useState } from 'react'

import Scroll, { Link } from 'react-scroll'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { useDeviceDetection } from 'hooks/useDeviceDetection'

import { Heading } from 'components/Heading'

import { HeaderStyle, Logo, Nav } from './styles'

export function Header() {
  const mock = useContext(TgdeveloperContext)

  const { isMobile } = useDeviceDetection()

  const [openMenu, setOpenMenu] = useState(false)

  const scroll = Scroll.animateScroll

  return (
    <HeaderStyle data-testid="header">
      <div className="container">
        <Heading level="h1">
          <Logo
            onClick={() => {
              scroll.scrollToTop()
            }}
            data-testid="logo"
          >
            <span>tg</span>developer
          </Logo>
        </Heading>
        <Nav className={`${openMenu ? 'open' : ''}`} data-testid="nav">
          <ul>
            {mock.menu.map(item => (
              <li key={item.id}>
                <Link
                  to={item.id}
                  title={item.label}
                  onSetInactive={() => setOpenMenu(false)}
                  spy
                  smooth
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </Nav>
        {isMobile ? (
          <FontAwesomeIcon
            icon={`${openMenu ? 'xmark' : 'bars'}`}
            onClick={() => {
              setOpenMenu(!openMenu)
            }}
            data-testid="menu"
          />
        ) : (
          ''
        )}
      </div>
    </HeaderStyle>
  )
}
