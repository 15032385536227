import { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { TgdeveloperContext } from 'context/Tgdeveloper'

import { Heading } from 'components/Heading'
import { Timeline } from 'components/Timeline'

import { EducationStyles } from './styles'

export function Education() {
  const mock = useContext(TgdeveloperContext)

  const { t } = useTranslation()

  return (
    <EducationStyles id="education">
      <div className="container">
        <Heading level="h2">{t('title.education')}</Heading>
        <Heading level="h3">{t('title.education_description')}</Heading>
        <Timeline color="white">
          {mock.education
            .slice(0)
            .reverse()
            .map(item => (
              <div className="timeline" key={Math.random()}>
                <div className="logo">
                  <img
                    src={`${process.env.PUBLIC_URL}images/education/${item.image}`}
                    alt={item.school}
                    loading="lazy"
                  />
                </div>
                <div className="info">
                  <Heading level="h4">{item.school}</Heading>
                  <Heading level="h5">{item.course}</Heading>
                  <small>{item.period}</small>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
        </Timeline>
      </div>
    </EducationStyles>
  )
}
