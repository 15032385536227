import { MOBILE_DEVICE } from 'hooks/useDeviceDetection'

import styled, { css } from 'styled-components'

export const ServicesStyles = styled.section`
  ${props => css`
    background-color: #fff;
    text-align: center;

    .services {
      .service {
        background: ${props.theme.color.gray[400]};
        transition: all 0.2s linear;
        padding: ${props.theme.container.gap};
        margin-bottom: ${props.theme.container.gap};
        min-height: calc(100% - ${props.theme.container.gap});

        .icon {
          display: block;
          font-size: calc(${props.theme.font.size.extrabig} * 2);
          color: ${props.theme.color.blue[500]};
          margin: 20px 0;
        }

        strong {
          display: block;
          color: #000;
          margin-bottom: 10px;
          font-size: ${props.theme.font.size.extralarge};
          transition: all 0.2s linear;
        }

        &:hover {
          background: #fff;

          span {
            color: ${props.theme.color.gray[500]};
          }

          strong {
            color: ${props.theme.color.blue[500]};
          }
        }
      }
    }
  `}
`
